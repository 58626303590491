import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch, useLocation } from 'react-router-dom';
import Landing from './pages/Landing.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../theme/styles/react.scss'
import { Layout, Tabs } from 'antd';
import DesktopMenu from './global/DesktopMenu';
import MobileMenu from './global/MobileMenu';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Cpf from './pages/Cpf';
import AffiliateCpf from './pages/AffiliateCpf';
import AffiliateSignUp from './pages/AffiliateSignUp';
import AboutUs from './pages/AboutUs'
import Terms from './pages/Terms'
import BuyNow from './pages/BuyNow'
import Results from './pages/Results'
import Campaigns from './pages/Campaigns'
import CampaignDetails from './pages/CampaignDetails'
import CampaignChoose from './pages/CampaignChoose'
import MyAccount from './pages/MyAccount'
import Testimonials from './pages/Testimonials'
import OrderDetails from './pages/OrderDetails'
import Live from './pages/Live'
import Policies from './pages/Policies'
import Faq from './pages/Faq';
import AppFooter from './AppFooter';
import ResetPassword from './pages/ResetPassword';
import ShoppingCart from './pages/ShoppingCart';
import PhoneConfirmation from './pages/PhoneConfirmation';
import { useHistory } from 'react-router-dom';
import Cookie from "js-cookie";
const { Content } = Layout;
import axios from 'axios';

const siderStyle = {
  textAlign: 'center',abst: '120px',
  color: 'white',
  backgroundColor: '#1677ff',
};

const logoDivStyle = {
  height: '100%',
};

const validateMessages = {
  required: "'${label}' está em branco",
};

function trackFacebookPixel() {
  const location = useLocation();

  useEffect(() => {
    if (window.fbq) window.fbq('track', 'PageView');
  }, [location.pathname]);
}

export const UserContext = React.createContext(null);
export const AffiliateContext = React.createContext(null);
export const CartContext = React.createContext(null);

export default function App({currentUser, currentAffiliate, flash, site_texts, cart, project, app_settings}) {
  const [user, setUser] = useState(currentUser);
  const [cartCount, setCartCount] = useState(cart.items.length);
  const history = useHistory();
  const [affiliate, setAffiliate] = useState(currentAffiliate);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;
  const redirect_to_login = app_settings.root_to_login && !currentUser;
  
  function clear_flash() {
    const queryParams = new URLSearchParams(location.search)
    
    if (queryParams.has('flash_notice'))
      queryParams.delete('flash_notice')
    if (queryParams.has('flash_alert'))
      queryParams.delete('flash_alert')
    history.replace({search: queryParams.toString()});
  }

  useEffect(() => {
    if (currentUser && Cookie.get('redirect_to')) {
      var redirect_to = Cookie.get('redirect_to')
      Cookie.remove('redirect_to');
      history.push(redirect_to);
      return;
    }

    if (currentUser && Cookie.get('go_to_purchase')) {
      Cookie.remove('go_to_purchase');
      axios.post('/api/int/v1/purchase_orders')
        .then(function(res){
          setCartCount(0);
          history.push(`/${project.name}/site/pedido/${res.data.id}`);
        })
        .catch(function(error){
          if (error.response?.status == 422 && error?.response?.data?.error == 'unconfirmed_phone') {
            Cookie.set('go_to_purchase', 'true', { path: '/', expires: 1 });
            history.push(`/${project.name}/site/confirmar-telefone`);
          } else {
            history.push(`/${project.name}/site/carrinho`);
          }
        });
    }

    if (flash.notice) {
      toast.info(flash.notice);
    }
    if (flash.notice) {
      toast.warning(flash.alert);
    }
    if (flash.notice || flash.alert) {
      clear_flash();
    }
  }, []);

  trackFacebookPixel();

  return (
    <Layout>
      <CartContext.Provider value={{ cartCount: cartCount, setCartCount: setCartCount }}>
        <DesktopMenu currentAffiliate={currentAffiliate} currentUser={currentUser} isMobile={isMobile} project={project} />
        <Content style={{ padding: '0', minHeight: 'calc(100vh - 64px - 69px)' }}>
          <ToastContainer autoClose={4000} />
          <AffiliateContext.Provider value={{ affiliate: affiliate, setAffiliate: setAffiliate }}>
            <UserContext.Provider value={{ user: user, setUser: setUser }}>
              <Switch>
                <Route exact path={["/:project_name", "/:project_name/site", "/:project_name/site/landing", "/", "/site", "/site/landing"]}>
                  {
                    redirect_to_login ?
                    <Redirect to={`/${project.name}/site/acesso/cpf`} />
                    :
                    <Landing project={project} isMobile={isMobile} currentUser={currentUser} />
                  }
                </Route>
                <Route exact path={["/:project_name/site/carrinho", "/site/carrinho"]}><ShoppingCart isMobile={isMobile} currentUser={currentUser} project={project} /></Route>
                <Route exact path={["/:project_name/site/faq", "/site/faq"]}><Faq /></Route>
                <Route exact path={["/:project_name/site/quem-somos", "/site/quem-somos"]}><AboutUs site_texts={site_texts} /></Route>
                <Route exact path={["/:project_name/site/acompanhar-sorteio", "/site/acompanhar-sorteio"]}><Live currentUser={currentUser} project={project} /></Route>
                <Route exact path={["/:project_name/site/compre-agora", "/site/compre-agora"]}><BuyNow currentUser={currentUser} project={project} isMobile={isMobile} /></Route>
                <Route exact path={["/:project_name/site/campanhas", "/site/campanhas"]}><Campaigns project={project} /></Route>
                <Route exact path={["/:project_name/site/campanhas/:campaignId", "/site/campanhas/:campaignId"]}><CampaignDetails project={project} isMobile={isMobile} currentUser={currentUser} /></Route>
                <Route exact path={["/:project_name/site/campanhas/:campaignId/escolha", "/site/campanhas/:campaignId/escolha"]}><CampaignChoose project={project} isMobile={isMobile} /></Route>
                <Route exact path={["/:project_name/site/resultados", "/site/resultados"]}><Results project={project} /></Route>
                <Route exact path={["/:project_name/site/depoimentos", "/site/depoimentos"]}><Testimonials project={project} /></Route>
                <Route exact path={["/:project_name/site/termos", "/site/termos"]}><Terms terms={site_texts.terms} /></Route>
                <Route exact path={["/:project_name/site/politicas", "/site/politicas"]}><Policies policies={site_texts.policies} /></Route>
                <Route exact path={["/:project_name/site/pedido/:orderId", "/site/pedido/:orderId"]}>
                  { currentUser ? <OrderDetails currentUser={currentUser} isMobile={isMobile} /> : <Redirect to={`/${project.name}/site/login`} /> }
                </Route>
                <Route exact path={["/site/pedido_direto/:secret/:orderId"]}><OrderDetails currentUser={null} isMobile={isMobile} /></Route>
                <Route exact path={["/:project_name/site/minha-conta", "/site/minha-conta"]}>
                  { currentUser ? <MyAccount app_settings={app_settings} currentUser={currentUser} project={project} /> : <Redirect to={`/${project.name}/site/login`} /> }
                </Route>
                <Route exact path={["/:project_name/site/confirmar-telefone", "/site/confirmar-telefone", "/:project_name/site/acesso/confirmar-telefone", "/site/acesso/confirmar-telefone"]}>
                  { currentUser ? <PhoneConfirmation isMobile={isMobile} project={project} app_settings={app_settings} /> : <Redirect to={`/${project.name}/site/login`} /> }
                </Route>
                <Route exact path={["/:project_name/site/cpf", "/:project_name/site/acesso/cpf", "/site/cpf", "/site/acesso/cpf"]}>
                  { currentUser ? <Redirect to={`/${project.name}/site/minha-conta`} /> : <Cpf isMobile={isMobile} project={project} /> }
                </Route>
                <Route exact path={["/:project_name/site/login", "/:project_name/site/acesso/login", "/site/login", "/site/acesso/login"]}>
                  { !currentUser ? <SignIn isMobile={isMobile} project={project} /> : <Redirect to={`/${project.name}/`} /> }
                </Route>
                <Route exact path={["/:project_name/site/cadastro", "/:project_name/site/acesso/cadastro", "/site/cadastro", "/site/acesso/cadastro"]}>
                  { !currentUser ? <SignUp isMobile={isMobile} project={project} app_settings={app_settings} /> : <Redirect to={`/${project.name}/`} /> }
                </Route>
                <Route exact path={["/:project_name/site/esqueci_minha_senha", "/:project_name/site/acesso/esqueci_minha_senha", "/site/esqueci_minha_senha", "/site/acesso/esqueci_minha_senha"]}>
                  { !currentUser ? <ResetPassword isMobile={isMobile} project={project} /> : <Redirect to={`/${project.name}/`} /> }
                </Route>
                <Route exact path={["/:project_name/site/revendedor/cpf", "/site/revendedor/cpf"]}><AffiliateCpf isMobile={isMobile}  project={project} /></Route>
                <Route exact path={["/:project_name/site/revendedor/cadastro", "/site/revendedor/cadastro"]}><AffiliateSignUp isMobile={isMobile} project={project} /></Route>
                <Route path="*">
                  <div style={{textAlign: 'center', paddingTop: '100px'}}>
                    <h1 style={{fontSize: 200, margin: 0}}>404</h1>
                    <p>Página não encontrada</p>
                    <a href={`/${project.name}/site`}>Voltar para a página inicial</a>
                    <div style={{height: '100px'}}>&nbsp;</div>
                  </div>
                </Route>
              </Switch>
            </UserContext.Provider>
          </AffiliateContext.Provider>
        </Content>
        <AppFooter project={project} site_texts={site_texts} />
        {
          isMobile && <div style={{height: '80px', backgroundColor: '#253875'}} id="spacer">&nbsp;</div>
        }
        <MobileMenu isMobile={isMobile} project={project} currentUser={currentUser} />
      </CartContext.Provider>
    </Layout>
  );
}
